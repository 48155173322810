var indexOf = [].indexOf;

angular.module('app.controllers').controller('ApplicationCtrl', ['$scope', '$route', '$rootScope', 'APIFavorites', '$sce', 'APIInteractions', '$interval', '$cookies', 'APITimes', 'UI', 'APITickets', 'APIEventdata', '$location', 'APIRead', 'APISlots', 'APIExhibitors', 'APIStatistics', 'APISpeakers', 'APIScan', '$timeout'].append(function(self, $route, root, APIFavorites, $sce, APIInteractions, $interval, $cookies, APITimes, UI, APITickets, APIEventdata, $location, APIRead, APISlots, APIExhibitors, APIStatistics, APISpeakers, APIScan, $timeout) {
  var amModalEl, getTime, params, timeInterval;
  params = $location.search();
  self.isShare = !!navigator.share;
  self.trustAsResourceUrl = $sce.trustAsResourceUrl;
  self.counter = {
    tuser: false,
    watch: 0,
    ex: 0,
    no: 0,
    sl: 0
  };
  self.$on('resetCounters', function(event) {
    return self.counter = null;
  });
  
  // Favorites list

  self.favorites = [];
  self.getFavorites = function(source = '') {
    var ref, shortcode;
    shortcode = ((ref = root.user) != null ? ref.shortcode : void 0) || root.tuser || 'test';
    return APIFavorites.query({
      shortcode: shortcode,
      tuser: root.tuser || null
    }, function(data) {
      self.favorites = data;
      if (source === 'proveSession' && (root.tuser != null)) {
        root.tuser = null;
        return $cookies.remove('tuser');
      }
    });
  };
  
  // get favorites when session load and we have user shortcode
  self.$on('getFavorites', function(event, source) {
    return self.getFavorites(source);
  });
  self.toggleFavs = function(entrycode, entry, shortcode = false) {
    var data, entry_id, entrydata, exhibitor_id, fav_code, fav_codes, fav_index, ref;
    if (entrycode === 'vi') {
      if (!self.ha()) {
        return root.lockModal.show();
      }
    }
    self.counter.tuser = false;
    if (!shortcode) {
      // request params
      shortcode = ((ref = root.user) != null ? ref.shortcode : void 0) || root.tuser || 'test';
    }
    entrydata = angular.copy(entry);
    exhibitor_id = entrydata.exhibitor_id || entrydata.id;
    if (entrydata.exhibitor_id === 0) {
      exhibitor_id = entrydata.exhibitor_id;
    }
    entry_id = '';
    if (entrydata.entry_id || entrydata.entry_id === 0) {
      entry_id = entrydata.entry_id;
    }
    data = entrydata;
    if (entrycode === 'ex' || entrycode === 'no' || entrycode === 'vi' || entrycode === 'sl') { // no need save data for some entries becauase they saved in database
      data = {};
    }
    fav_code = `${entrycode}${exhibitor_id}${entry_id}`;
    fav_codes = self.favorites.map(function(item) {
      return item.fav_code;
    });
    fav_index = fav_codes.indexOf(fav_code);
    // remove from favorites
    if (fav_index !== -1) {
      (new APIFavorites()).$delete({
        shortcode: shortcode,
        entrycode: entrycode,
        exhibitor_id: exhibitor_id,
        entry_id: entry_id
      }, function() {
        self.favorites.splice(fav_index, 1);
        if ((entrycode !== 'vi') || self.at('favs')) {
          return self.$emit('forceFavs');
        }
      }, function(errorData) {
        return console.debug(errorData.data.error);
      });
    } else {
      // save as favorites
      if (self.counter.watch >= 10 && (root.tuser != null) && !self.ha() && (entrycode === 'ex' || entrycode === 'no' || entrycode === 'sl')) {
        self.counter.tuser = true;
        return self.amModal.show();
      }
      (new APIFavorites(data)).$save({
        shortcode: shortcode,
        entrycode: entrycode,
        exhibitor_id: exhibitor_id,
        entry_id: entry_id
      }, function() {
        // track events
        if (entrycode === 'ex') { // exhibitors
          self.trackEvent({
            exhibitor_id: exhibitor_id,
            description: 'exhibitor_save ' + entrydata.exhibitor_name || exhibitor_id
          });
        }
        if (entrycode === 'sl') { // program slot
          self.trackEvent({
            exhibitor_id: null,
            description: 'program_save ' + (entrydata.title_de || entrydata.title_en || entrydata.title)
          });
        }
        if (entrycode === 'no') { // news
          self.trackEvent({
            exhibitor_id: null,
            description: 'news_save ' + entrydata.title
          });
        }
        entrydata.fav_code = fav_code;
        return self.favorites.push(entrydata);
      }, function(response) {
        return UI.notify(false, response.data.error);
      });
    }
  };
  self.isFavs = function(entrycode, exhibitor_id = null, entry_id = '') {
    var fav_code, fav_codes;
    if (exhibitor_id == null) {
      
      //return false unless self.ha() # always return false for GUEST user
      return false;
    }
    fav_code = `${entrycode}${exhibitor_id}${entry_id}`;
    fav_codes = self.favorites.map(function(item) {
      return item.fav_code;
    });
    return indexOf.call(fav_codes, fav_code) >= 0;
  };
  self.$watch('favorites', function(newObj, oldObj) {
    if (!newObj) {
      return;
    }
    return self.updateFavBadges(newObj);
  }, true);
  self.$watch('counter.watch', function(newObj, oldObj) {
    if (!oldObj) {
      return;
    }
    if (parseInt(newObj) === 10 && !self.ha()) {
      return self.amModal.show();
    }
  }, true);
  self.updateFavBadges = function(newObj = null) {
    var c1, counter1, favs, hidden;
    favs = angular.copy(newObj);
    if ((favs != null ? favs.length : void 0) === 0) {
      return self.counter.watch = 0;
    }
    // exclude not visible elements like request
    hidden = ['vi', 'rb', 'rp'];
    favs = favs.filter(function(item) {
      var ref;
      return ref = item.fav_code.substr(0, 2), indexOf.call(hidden, ref) < 0;
    });
    counter1 = ['ex', 'no', 'sl'];
    c1 = favs.filter(function(item) {
      var ref;
      return ref = item.fav_code.substr(0, 2), indexOf.call(counter1, ref) >= 0;
    });
    if (self.counter == null) {
      self.counter = {};
    }
    self.counter.watch = c1.length;
    self.counter.ex = favs.filter(function(item) {
      var ref;
      return (ref = item.fav_code.substr(0, 2)) === 'ex';
    }).length;
    self.counter.no = favs.filter(function(item) {
      var ref;
      return (ref = item.fav_code.substr(0, 2)) === 'no';
    }).length;
    return self.counter.sl = favs.filter(function(item) {
      var ref;
      return (ref = item.fav_code.substr(0, 2)) === 'sl';
    }).length;
  };
  
  // Track events

  self.trackEvent = function(data) {
    var ref, ref1, ref2;
    data.visible_to_supplier = false;
    if ((data.exhibitor_id != null) && ((ref = root.session) != null ? (ref1 = ref.referred_supplier) != null ? ref1.id : void 0 : void 0)) {
      data.visible_to_supplier = data.exhibitor_id === root.session.referred_supplier.id;
    }
    if ((ref2 = root.user) != null ? ref2.auth_ticket : void 0) {
      return (new APIInteractions(data)).$save({
        auth_ticket: root.user.auth_ticket
      }, function(data) {}, function(response) {
        return UI.notify(false, response.data.error);
      });
    } else {
      return (new APIStatistics(data)).$save({}, function(response) {
        var reg_ref;
        reg_ref = $cookies.get('reg_ref') || null;
        if (reg_ref != null) {
          self.toggleFavs('ac', angular.copy(data), reg_ref);
        }
      }, function(response) {
        return UI.notify(false, response.data.error);
      });
    }
  };
  self.customStat = function(exhibitor_id = null, action, description = '', visible_to_presenter = false, visible_to_supplier = false) {
    var data;
    data = {
      description: action + (description ? ' ' + description : ''),
      visible_to_presenter: visible_to_presenter,
      visible_to_supplier: visible_to_supplier
    };
    if (exhibitor_id != null) {
      data.exhibitor_id = exhibitor_id;
    }
    return self.trackEvent(data);
  };
  self.openFile = function(exhibitor_id = null, file_name = '') {
    return self.customStat(exhibitor_id, 'file_open', file_name, false, false);
  };
  self.openLink = function(exhibitor_id = null, link_name = '') {
    return self.customStat(exhibitor_id, 'link_open', link_name, false, false);
  };
  
  // APP time

  getTime = function() {
    var cookieTime, end, newstate, start, substates;
    cookieTime = $cookies.get("app_time") || null;
    if (moment(cookieTime, 'DD/MM/YYYY kk:mm').isValid()) {
      self.appTime = moment(cookieTime, 'DD/MM/YYYY kk:mm').format();
    } else {
      self.appTime = moment().format();
    }
    start = moment(self.setting('event_start'));
    end = moment(self.setting('event_end'));
    if (moment(self.appTime).isBefore(start)) {
      newstate = 'before';
    } else if (moment(self.appTime).isBetween(start, end)) {
      newstate = 'live';
    } else if (moment(self.appTime).isAfter(end)) {
      newstate = 'after';
    }
    if (newstate !== root.state) {
      $route.reload();
    }
    self.setState(newstate);
    substates = self.setting('substates') || [];
    substates = substates.filter(function(item) {
      var substate_moment;
      if (!item.datetime) {
        return self.isState(item.state);
      }
      substate_moment = moment().format();
      return moment(substate_moment).isSameOrBefore(self.appTime);
    }).map(function(item) {
      return item.substate;
    });
    if (!angular.equals(root.substates, substates)) {
      $route.reload();
    }
    return root.substates = substates;
  };
  self.$on('fetchTime', function(event) {
    return getTime();
  });
  // update app time each minute
  timeInterval = $interval(getTime, 20000);
  // clear time interval
  self.$on('$destroy', function() {
    if (angular.isDefined(timeInterval)) {
      $interval.cancel(timeInterval);
      return timeInterval = void 0;
    }
  });
  self.ha = function() {
    var ref;
    return (root.session != null) && (((ref = root.user) != null ? ref.auth_ticket : void 0) != null);
  };
  
  // Data modal

  self.videoModalEl = document.getElementById('videoModal');
  if (self.videoModalEl) {
    self.videoModal = bootstrap.Modal.getOrCreateInstance(self.videoModalEl);
    self.videoModalEl.addEventListener('hide.bs.modal', function(event) {
      self.closeDataModal();
      return self.$applyAsync();
    });
  }
  self.modalData = null;
  self.showDataModal = function(source) {
    return self.$emit('showDataModal', source);
  };
  self.closeDataModal = function() {
    return self.$emit('closeDataModal');
  };
  self.$on('showDataModal', function(event, source) {
    return self.modalData = source;
  });
  self.$on('closeDataModal', function(event) {
    self.modalData = null;
    return self.$applyAsync();
  });
  
  // Slider settings

  self.imageSlider = {
    dots: true,
    dotsClass: 'slider-dots',
    customPaging: function(slider, i) {
      if (slider.slideCount === 1 || slider.slideCount === slider.options.slidesToShow) {
        return false;
      }
      return "<a class='slider-dots-item'></a>";
    },
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<div class="slider-control slider-control-prev"></div>',
    nextArrow: '<div class="slider-control slider-control-next"></div>',
    speed: 500,
    fade: true,
    autoplay: false,
    autoplaySpeed: 5000,
    useCSS: false,
    cssEase: 'linear'
  };
  
  // Modals

  amModalEl = document.getElementById('amountModal');
  if (amModalEl != null) {
    self.amModal = bootstrap.Modal.getOrCreateInstance(amModalEl);
  }
  self.search2 = "";
  self.searchByTerm2 = function(search = true) {
    if (search) {
      return $location.path("/suche").search({
        q: self.search2
      });
    }
  };
  self.filterExhibitors = function(exhibitor_name) {
    var cookiesName, defaultParams, ref, tableName;
    tableName = 'c.e.' + (((ref = root.user) != null ? ref.shortcode : void 0) || 'test');
    cookiesName = 't.' + tableName;
    defaultParams = {
      search: exhibitor_name,
      categories: "",
      brands: "",
      subject_area: ""
    };
    $cookies.putObject(cookiesName, defaultParams);
    return $location.path("/aussteller");
  };
  self.shareNative = function(url = '', title, text = '') {
    var host;
    if (!navigator.share) {
      return;
    }
    host = $location.protocol() + "://" + $location.host();
    if (url) {
      url = host + url;
    } else {
      url = $location.absUrl();
    }
    return navigator.share({
      title: title,
      text: text,
      url: url
    }).then(function() {
      return console.log('Successful share');
    }).catch(function(error) {
      return console.log('Error sharing', error);
    });
  };
  self.openBoothsModal = function(booths = null, exhibitor = '', subject = 0) {
    if (!(booths.length > 1)) {
      return;
    }
    return root.$broadcast('onBoothModalOpen', booths, exhibitor, subject);
  };
  return self.focusSearch = function(page) {
    return;
    return root.$broadcast('onCloseSearch', page);
  };
}));

angular.module('app.controllers').controller('BoothsCtrl', ['$scope', '$rootScope', 'UI'].append(function(self, root, UI) {
  self.subject = 0;
  self.exhibitor = '';
  self.booths = [];
  self.$on('onBoothModalOpen', function(event, booths = [], exhibitor = '', subject = 0) {
    self.subject = subject;
    self.exhibitor = exhibitor;
    self.booths = booths;
    return self.boothsModal.show();
  });
  self.boothsModalEl = document.getElementById('boothsModal');
  if (self.boothsModalEl) {
    self.boothsModal = bootstrap.Modal.getOrCreateInstance(self.boothsModalEl);
    self.boothsModalEl.addEventListener('hidden.bs.modal', function(event) {
      self.subject = 0;
      self.exhibitor = '';
      self.booths = [];
      return self.$applyAsync();
    });
    return self.boothsModalEl.addEventListener('shown.bs.modal', function(event) {
      return self.$applyAsync();
    });
  }
}));
