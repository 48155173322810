angular.module('app.controllers').controller 'ApplicationCtrl', [
  '$scope', '$route', '$rootScope', 'APIFavorites', '$sce', 'APIInteractions', '$interval', '$cookies', 'APITimes', 'UI', 'APITickets', 'APIEventdata', '$location', 'APIRead', 'APISlots', 'APIExhibitors', 'APIStatistics', 'APISpeakers', 'APIScan', '$timeout'
].append (self, $route, root, APIFavorites, $sce, APIInteractions, $interval, $cookies, APITimes, UI, APITickets, APIEventdata, $location, APIRead, APISlots, APIExhibitors, APIStatistics, APISpeakers, APIScan, $timeout) ->
  
  params = $location.search()

  self.isShare = !!navigator.share

  self.trustAsResourceUrl = $sce.trustAsResourceUrl
  
  self.counter = 
    tuser: false 
    watch: 0
    ex: 0
    no: 0
    sl: 0


  self.$on 'resetCounters', (event) ->
    self.counter = null 


  #
  # Favorites list
  #
  self.favorites = []
  self.getFavorites = (source = '') ->    
    
    shortcode = root.user?.shortcode || root.tuser || 'test'

    APIFavorites.query {shortcode: shortcode, tuser: root.tuser || null}, (data) ->
      self.favorites = data

      if source == 'proveSession' && root.tuser?
        root.tuser = null
        $cookies.remove('tuser')

  
  # get favorites when session load and we have user shortcode
  self.$on 'getFavorites', (event, source) ->
    self.getFavorites(source)

  self.toggleFavs = (entrycode, entry, shortcode = false) -> 
    if entrycode in ['vi']
      return root.lockModal.show() unless self.ha()

    self.counter.tuser = false

    # request params
    shortcode     = root.user?.shortcode || root.tuser || 'test' unless shortcode
    entrydata     = angular.copy(entry)
    exhibitor_id  = entrydata.exhibitor_id || entrydata.id
    exhibitor_id  = entrydata.exhibitor_id if entrydata.exhibitor_id == 0
    
    entry_id = ''
    entry_id = entrydata.entry_id if entrydata.entry_id || entrydata.entry_id == 0
    
    data = entrydata
    data = {} if entrycode in ['ex', 'no', 'vi', 'sl'] # no need save data for some entries becauase they saved in database
      
    fav_code  = "#{entrycode}#{exhibitor_id}#{entry_id}"
    fav_codes = self.favorites.map((item) -> return item.fav_code)       
    fav_index = fav_codes.indexOf(fav_code)

    # remove from favorites
    if fav_index != -1      
      (new APIFavorites).$delete {shortcode: shortcode, entrycode: entrycode, exhibitor_id: exhibitor_id, entry_id: entry_id}, () ->
        self.favorites.splice(fav_index, 1)

        self.$emit('forceFavs') if entrycode not in ['vi'] || self.at('favs')
        
      , (errorData) -> console.debug errorData.data.error
    # save as favorites
    else

      if self.counter.watch >= 10 && root.tuser? && !self.ha() && entrycode in ['ex', 'no', 'sl']
        self.counter.tuser = true
        return self.amModal.show()

      (new APIFavorites data).$save {shortcode: shortcode, entrycode: entrycode, exhibitor_id: exhibitor_id, entry_id: entry_id}, () ->

        # track events
        if entrycode == 'ex' # exhibitors
          self.trackEvent({exhibitor_id: exhibitor_id, description: 'exhibitor_save ' + entrydata.exhibitor_name || exhibitor_id})

        if entrycode == 'sl' # program slot
          self.trackEvent({exhibitor_id: null, description: 'program_save ' + (entrydata.title_de || entrydata.title_en || entrydata.title)})

        if entrycode in ['no'] # news
          self.trackEvent({exhibitor_id: null, description: 'news_save ' + entrydata.title})

        entrydata.fav_code = fav_code
        self.favorites.push entrydata

      , (response) -> 
        UI.notify false, response.data.error

    return

  self.isFavs = (entrycode, exhibitor_id = null, entry_id = '') -> 
    #return false unless self.ha() # always return false for GUEST user
    return false unless exhibitor_id?
    fav_code = "#{entrycode}#{exhibitor_id}#{entry_id}"
    fav_codes = self.favorites.map((item) -> return item.fav_code)
    return fav_code in fav_codes

  self.$watch 'favorites', (newObj, oldObj) -> 
    return unless newObj
    self.updateFavBadges(newObj)
  , true

  self.$watch 'counter.watch', (newObj, oldObj) -> 
    return unless oldObj
    return self.amModal.show() if parseInt(newObj) == 10 && !self.ha()
  , true

  self.updateFavBadges = (newObj = null) ->
    favs = angular.copy(newObj)
    return self.counter.watch = 0 if favs?.length == 0

    # exclude not visible elements like request
    hidden = ['vi', 'rb', 'rp']    
    favs = favs.filter (item) -> return item.fav_code.substr(0,2) not in hidden


    counter1 = ['ex', 'no', 'sl']
    c1 = favs.filter (item) -> return item.fav_code.substr(0,2) in counter1

    self.counter = {} unless self.counter?

    self.counter.watch = c1.length
    
    self.counter.ex = favs.filter((item) -> return item.fav_code.substr(0,2) in ['ex']).length
    self.counter.no = favs.filter((item) -> return item.fav_code.substr(0,2) in ['no']).length
    self.counter.sl = favs.filter((item) -> return item.fav_code.substr(0,2) in ['sl']).length


  #
  # Track events
  #

  self.trackEvent = (data) ->  
   
    data.visible_to_supplier = false

    if data.exhibitor_id? && root.session?.referred_supplier?.id
      data.visible_to_supplier = data.exhibitor_id == root.session.referred_supplier.id    

    if root.user?.auth_ticket
      (new APIInteractions data).$save {auth_ticket: root.user.auth_ticket}, (data) ->
        return
      , (response) ->
        UI.notify false, response.data.error
    else
      (new APIStatistics data).$save {}, (response) ->
        reg_ref = $cookies.get('reg_ref') || null
        self.toggleFavs('ac', angular.copy(data), reg_ref) if reg_ref?
        return
      , (response) ->
        UI.notify false, response.data.error


  self.customStat = (exhibitor_id = null, action, description = '', visible_to_presenter = false, visible_to_supplier = false) ->
    data =
      description: action + (if description then ' ' + description else '')
      visible_to_presenter: visible_to_presenter
      visible_to_supplier: visible_to_supplier
    
    data.exhibitor_id = exhibitor_id if exhibitor_id?

    self.trackEvent(data)

  self.openFile = (exhibitor_id = null, file_name = '') ->
    self.customStat(exhibitor_id, 'file_open', file_name, false, false)
  
  self.openLink = (exhibitor_id = null, link_name = '') ->
    self.customStat(exhibitor_id, 'link_open', link_name, false, false)

  #
  # APP time
  #
  
  getTime = () ->
    cookieTime = $cookies.get("app_time") || null
  
    if moment(cookieTime, 'DD/MM/YYYY kk:mm').isValid()
      self.appTime = moment(cookieTime, 'DD/MM/YYYY kk:mm').format()
    else
      self.appTime = moment().format()
 
    start = moment(self.setting('event_start'))
    end   = moment(self.setting('event_end'))
    if moment(self.appTime).isBefore(start)
      newstate = 'before'
    else if moment(self.appTime).isBetween(start, end)
      newstate = 'live'
    else if moment(self.appTime).isAfter(end)
      newstate = 'after'  
    


    $route.reload() if newstate != root.state
    self.setState(newstate)


    substates = self.setting('substates') || []
    substates = substates.filter((item) -> 
      return self.isState(item.state) unless item.datetime
      substate_moment = moment().format()
      return moment(substate_moment).isSameOrBefore(self.appTime)
    ).map((item) -> return item.substate)
    
    $route.reload() unless angular.equals(root.substates, substates)
    
    root.substates = substates

  self.$on 'fetchTime', (event) ->
    getTime()

  # update app time each minute
  timeInterval = $interval getTime, 20000

  # clear time interval
  self.$on '$destroy', ->
    if angular.isDefined(timeInterval)
      $interval.cancel(timeInterval)
      timeInterval = undefined

  self.ha = () ->
    return root.session? && root.user?.auth_ticket?

  #
  # Data modal
  #

  self.videoModalEl = document.getElementById('videoModal')
  
  if self.videoModalEl
    self.videoModal = bootstrap.Modal.getOrCreateInstance(self.videoModalEl)

    self.videoModalEl.addEventListener('hide.bs.modal', (event) ->
      self.closeDataModal()
      self.$applyAsync()
    )

  self.modalData = null
  self.showDataModal = (source) ->
    self.$emit 'showDataModal', source  

  self.closeDataModal = () ->
    self.$emit 'closeDataModal'

  self.$on 'showDataModal', (event, source) ->
    self.modalData = source

  self.$on 'closeDataModal', (event) ->
    self.modalData = null
    self.$applyAsync()

  #
  # Slider settings
  #

  self.imageSlider =
    dots: true
    dotsClass: 'slider-dots'
    customPaging : (slider, i) -> 
      return false if slider.slideCount == 1 || slider.slideCount == slider.options.slidesToShow
      return "<a class='slider-dots-item'></a>"

    slidesToShow: 1
    slidesToScroll: 1
    prevArrow: '<div class="slider-control slider-control-prev"></div>'
    nextArrow: '<div class="slider-control slider-control-next"></div>'
    speed: 500
    fade: true
    autoplay: false
    autoplaySpeed: 5000
    useCSS: false
    cssEase: 'linear'

  #
  # Modals
  #

  amModalEl    = document.getElementById('amountModal')
  if amModalEl?
    self.amModal = bootstrap.Modal.getOrCreateInstance(amModalEl)

  self.search2 = ""
  self.searchByTerm2 = (search = true) ->   
    return $location.path("/suche").search({q: self.search2}) if search


    
  self.filterExhibitors = (exhibitor_name) ->

    tableName   = 'c.e.' + (root.user?.shortcode || 'test')
    cookiesName = 't.' + tableName

    defaultParams = 
      search: exhibitor_name
      categories: ""
      brands: ""
      subject_area: ""

    $cookies.putObject(cookiesName, defaultParams)

    return $location.path("/aussteller")

  self.shareNative = (url = '', title, text = '') ->
    return unless navigator.share
    host = $location.protocol() + "://" + $location.host()
    if url
      url = host + url
    else
      url = $location.absUrl()
      
    navigator.share({
      title: title
      text: text
      url: url
    }).then(() -> 
      console.log('Successful share')
    ).catch((error) -> 
      console.log('Error sharing', error)
    )



  self.openBoothsModal = (booths = null, exhibitor = '', subject = 0) ->
    return unless booths.length > 1
    root.$broadcast 'onBoothModalOpen', booths, exhibitor, subject

  self.focusSearch = (page) ->
    return
    root.$broadcast 'onCloseSearch', page

angular.module('app.controllers').controller 'BoothsCtrl', [
  '$scope', '$rootScope', 'UI'
].append (self, root, UI) ->  
  
  self.subject = 0
  self.exhibitor = ''
  self.booths = []

  self.$on 'onBoothModalOpen', (event, booths = [], exhibitor = '', subject = 0) ->
    self.subject = subject
    self.exhibitor = exhibitor
    self.booths = booths
    self.boothsModal.show()

  self.boothsModalEl = document.getElementById('boothsModal')

  if self.boothsModalEl
    self.boothsModal = bootstrap.Modal.getOrCreateInstance(self.boothsModalEl)
  
    self.boothsModalEl.addEventListener('hidden.bs.modal', (event) ->
      self.subject = 0
      self.exhibitor = ''
      self.booths = []
      self.$applyAsync()
    )

    self.boothsModalEl.addEventListener('shown.bs.modal', (event) ->
      self.$applyAsync()
    )

